.App-light {
  align-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.App-dark {
  align-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App {
  background-color: #282c34;
  align-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 7s ease-in-out alternate;
  }
}

.meditationApp-light {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.meditationApp-dark {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg) scale(1);
    filter: hue-rotate(45deg);
  }
  100% {
    transform: rotate(97deg) scale(2);
  }
}
