
.player-wrapper {
    display: none;
}

.playerApp {
    margin: auto;
}

.playerCard {
    max-width: 600px;
}

.playerBarMax {
    background: rgba(255,225,255,.17);
    height: 350px;
    border:1px solid rgba(225,225,255,0.1);
    z-index: 2;
}

.playerBarMaxDark {
    background: rgba(255,225,255,.17);
    height: 350px;
    border:1px solid rgba(225,225,255,0.1);
    z-index: 2;
}

.playerBar {
    background: rgba(255,225,255,.17);
    height: 110px;
    z-index: 2;
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;  

}

.playerBarDark {
    background: rgba(255,225,255,.17);
    height: 110px;
    z-index: 2;
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;  
}

.sliderBox {
    margin-left: 2%;
    margin-right: 2%;
}
.sliderBoxMax {
    background-color: rgba(243, 243, 243, 0.418);
    padding: 2%;
}

.timePlayed,
.timeRemaining {
    font-size: 15px;
}

.timePlayed {
    float: left;
}
.timeRemaining {
    float: right;
}
.meditationHeader {
    background: linear-gradient(rgba(247, 247, 247, 0), rgba(38, 46, 56, 0.4));
    margin: 0;
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    color:rgb(109, 209, 255);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationHeaderContainerBar {
    backdrop-filter: blur(2px);
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;  
    margin: 0;
    padding: 0;
}
.meditationHeaderContainer {
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;  
    margin: 0;
    padding: 0;
}
.meditationHeaderInnerContainer {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.meditationTitle {
    background-color: rgba(200, 197, 204, 0.507);
    padding: 10px;
    margin-bottom: 0px;
    color:rgb(151, 44, 124);
    font-weight: 700;
    letter-spacing: 1px;
}

.authorName {
    background-color: rgba(160, 94, 235, 0.2);
    margin: 0px;
    padding: 5px;
    color:rgb(37, 37, 37);
}

.description {
    background-color: rgba(141, 133, 150, 0.2);
    border-radius: 5px;
    padding: 15px;
    padding-right: 25px;
    padding-left: 25px;
}


.playerBarPlayButton{
    margin-right: 20px !important;
}
.playerBarPlayButtonIcon {
    font-size: 2rem;
    color: rgba(47, 108, 136, 0.76);
    border: 2px solid rgba(47, 108, 136, 0.76);
    padding: 8px;
    border-radius: 100%;
    animation: colorFade infinite 7s ease-in-out alternate;
}
.meditationHeaderBar {
    animation: colorFade infinite 7s ease-in-out alternate;
    margin: auto;
    color:rgba(47, 108, 136, 0.76);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationHeaderBarSeperator {
    margin: auto;
}
.meditationTitleBar {
    transform: scale(0.8);
    margin: auto;
    margin-top: 5px;
    color:rgba(70, 9, 94, 0.685);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationArtistBar {
    margin: auto;
    color:rgba(29, 53, 63, 0.8);
    letter-spacing: 1px;
}
.meditationTimePlayedBar {
    transform: scale(0.8);
    margin: auto;
    color:rgba(70, 9, 94, 0.55);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationTimeRemainingBar {
    margin: auto;
    color:rgba(70, 9, 94, 0.4);
    letter-spacing: 1px;
}

.playerBarPlayButtonDark{
    margin-right: 20px !important;
}
.playerBarPlayButtonIconDark {
    font-size: 2rem;
    color: rgba(109, 209, 255, 0.76);
    border: 2px solid rgba(109, 209, 255, 0.76);
    padding: 8px;
    border-radius: 100%;
    animation: colorFade infinite 7s ease-in-out alternate;
}
.meditationHeaderBarDark {
    animation: colorFade infinite 7s ease-in-out alternate;
    margin: auto;
    color:rgba(109, 209, 255, 0.76);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationHeaderBarSeperatorDark {
    margin: auto;
}
.meditationTitleBarDark {
    transform: scale(0.8);
    margin: auto;
    color:rgba(246, 255, 117, 0.568);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationArtistBarDark {
    margin: auto;
    color:rgba(213, 237, 247, 0.8);
    letter-spacing: 1px;
}
.meditationTimePlayedBarDark {
    transform: scale(0.8);
    margin: auto;
    color:rgba(176, 221, 241, 0.74);
    font-weight: 700;
    letter-spacing: 1px;
}
.meditationTimeRemainingBarDark {
    margin: auto;
    color:rgba(176, 221, 241, 0.562);
    letter-spacing: 1px;
}

@keyframes colorFade {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(90deg);
  }
}

