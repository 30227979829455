.breathBox {
    height: 40vmin;
    pointer-events: none;
}
.breathBoxDark {
  filter: brightness(.5) hue-rotate(80deg);
}

  
  @media (prefers-reduced-motion: no-preference) {
    .breathBoxAnimated {
      animation: breathBox-spin infinite 7s ease-in-out alternate;
    }
  }
  
  @keyframes breathBox-spin {
    0% {
      transform: rotate(0deg) scale(1);
      filter: hue-rotate(45deg);
      opacity: 0.7;
    }
    100% {
      transform: rotate(97deg) scale(2);
    }
  }


 